import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect, useState } from 'react'
import { client } from '../apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTimeframe } from './Application'
import {
  getPercentChange,
  getBlockFromTimestamp,
  getBlocksFromTimestamps,
  getTimestampsForChanges,
  get1D_7D_1M_Timestamp,
  get2DayPercentChange,
  getTimeframe,
} from '../utils'
import {
  GLOBAL_DATA,
  GLOBAL_TXNS,
  GLOBAL_MINTS,
  GLOBAL_BURNS,
  GLOBAL_CHART,
  ETH_PRICE,
  ALL_PAIRS,
  ALL_TOKENS,
  TOP_LPS_PER_PAIRS,
  POINTS_LIST,
  TOP_ACCOUNTS_LIST,
  TOP_ACCOUNTS_HISTORICAL_LIST
} from '../apollo/queries'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useAllPairData } from './PairData'
import { useTokenChartDataCombined } from './TokenData'
import { Contract, providers, utils } from 'ethers'
import ContractCheckerAbi from '../abis/ContractChecker.json'
import Blacklist from '../constants/Blacklist.json'
const UPDATE = 'UPDATE'
const UPDATE_TXNS = 'UPDATE_TXNS'
const ADD_TXNS = 'ADD_TXNS'
const UPDATE_MINTS = 'UPDATE_MINTS'
const ADD_MINTS = 'ADD_MINTS'
const UPDATE_BURNS = 'UPDATE_BURNS'
const ADD_BURNS = 'ADD_BURNS'
const UPDATE_CHART = 'UPDATE_CHART'
const UPDATE_ETH_PRICE = 'UPDATE_ETH_PRICE'
const ETH_PRICE_KEY = 'ETH_PRICE_KEY'
const UPDATE_ALL_PAIRS_IN_UNISWAP = 'UPDAUPDATE_ALL_PAIRS_IN_UNISWAPTE_TOP_PAIRS'
const UPDATE_ALL_TOKENS_IN_UNISWAP = 'UPDATE_ALL_TOKENS_IN_UNISWAP'
const UPDATE_TOP_LPS = 'UPDATE_TOP_LPS'
const UPDATE_TOP_POINTS = 'UPDATE_TOP_POINTS'
const UPDATE_HISTORY_POINTS = 'UPDATE_HISTORY_POINTS'

const offsetVolumes = [
  '0x9ea3b5b4ec044b70375236a281986106457b20ef',
  '0x05934eba98486693aaec2d00b0e9ce918e37dc3f',
  '0x3d7e683fc9c86b4d653c9e47ca12517440fad14e',
  '0xfae9c647ad7d89e738aba720acf09af93dc535f7',
  '0x7296368fe9bcb25d3ecc19af13655b907818cc09',
]

const AUTOSWAP_ADDRESSES = [
  "0x0a25b7de531b9e7c2c7e45df59f8e855e9c6ba49",
  "0x835230215f9cd7b816788fdfd6bc3ed0447fa141",
  "0x9cc7784b02c1b9978a7c5f859117730667adeb9e",
  "0xebee2f9b23aa4da2d93b5ca5325b0bb3e939dc59",
  "0xbc8518d5e9d36c4e21deb7ba5f29a88327b914be"
]

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)

const GlobalDataContext = createContext()

function useGlobalDataContext() {
  return useContext(GlobalDataContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE: {
      const { data } = payload
      return {
        ...state,
        globalData: data,
      }
    }
    case UPDATE_TXNS: {
      const { transactions } = payload
      return {
        ...state,
        transactions,
      }
    }
    case ADD_TXNS: {
      const { transactions } = payload
      return {
        ...state,
        transactions: {
          mints: [...state.transactions?.mints, ...transactions.mints],
          burns: [...state.transactions?.burns, ...transactions.burns],
          swaps: [...state.transactions?.swaps, ...transactions.swaps]
        }
      }
    }
    case UPDATE_MINTS: {
      const { mints } = payload
      return {
        ...state,
        mints,
      }
    }
    case ADD_MINTS: {
      const { mints } = payload
      return {
        ...state,
        mints: [...state.mints, ...mints]
      }
    }
    case UPDATE_BURNS: {
      const { burns } = payload
      return {
        ...state,
        burns,
      }
    }
    case ADD_BURNS: {
      const { burns } = payload
      return {
        ...state,
        burns: [...state.burns, ...burns]
      }
    }
    case UPDATE_CHART: {
      const { daily, weekly } = payload
      return {
        ...state,
        chartData: {
          daily,
          weekly,
        },
      }
    }
    case UPDATE_ETH_PRICE: {
      const { ethPrice, oneDayPrice, ethPriceChange, oneWeekPrice, oneMonthPrice } = payload
      return {
        [ETH_PRICE_KEY]: ethPrice,
        oneDayPrice,
        ethPriceChange,
        oneWeekPrice,
        oneMonthPrice,
      }
    }

    case UPDATE_ALL_PAIRS_IN_UNISWAP: {
      const { allPairs } = payload
      return {
        ...state,
        allPairs,
      }
    }

    case UPDATE_ALL_TOKENS_IN_UNISWAP: {
      const { allTokens } = payload
      return {
        ...state,
        allTokens,
      }
    }

    case UPDATE_TOP_LPS: {
      const { topLps } = payload
      return {
        ...state,
        topLps,
      }
    }

    case UPDATE_TOP_POINTS: {
      const { topPoints } = payload
      return {
        ...state,
        topPoints,
      }
    }

    case UPDATE_HISTORY_POINTS: {
      const { historyPoints } = payload
      return {
        ...state,
        historyPoints,
      }
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, {})
  const update = useCallback((data) => {
    dispatch({
      type: UPDATE,
      payload: {
        data,
      },
    })
  }, [])

  const updateTransactions = useCallback((transactions) => {
    dispatch({
      type: UPDATE_TXNS,
      payload: {
        transactions,
      },
    })
  }, [])

  const addTransactions = useCallback((transactions) => {
    dispatch({
      type: ADD_TXNS,
      payload: {
        transactions,
      },
    })
  }, [])

  const updateMints = useCallback((mints) => {
    dispatch({
      type: UPDATE_MINTS,
      payload: {
        mints,
      },
    })
  }, [])

  const addMints = useCallback((mints) => {
    dispatch({
      type: ADD_MINTS,
      payload: {
        mints,
      },
    })
  }, [])

  const updateBurns = useCallback((burns) => {
    dispatch({
      type: UPDATE_BURNS,
      payload: {
        burns,
      },
    })
  }, [])

  const addBurns = useCallback((burns) => {
    dispatch({
      type: ADD_BURNS,
      payload: {
        burns,
      },
    })
  }, [])

  const updateChart = useCallback((daily, weekly) => {
    dispatch({
      type: UPDATE_CHART,
      payload: {
        daily,
        weekly,
      },
    })
  }, [])

  const updateEthPrice = useCallback((ethPrice, oneDayPrice, ethPriceChange, oneWeekPrice, oneMonthPrice) => {
    dispatch({
      type: UPDATE_ETH_PRICE,
      payload: {
        ethPrice,
        oneDayPrice,
        ethPriceChange,
        oneWeekPrice,
        oneMonthPrice
      },
    })
  }, [])

  const updateAllPairsInUniswap = useCallback((allPairs) => {
    dispatch({
      type: UPDATE_ALL_PAIRS_IN_UNISWAP,
      payload: {
        allPairs,
      },
    })
  }, [])

  const updateAllTokensInUniswap = useCallback((allTokens) => {
    dispatch({
      type: UPDATE_ALL_TOKENS_IN_UNISWAP,
      payload: {
        allTokens,
      },
    })
  }, [])

  const updateTopLps = useCallback((topLps) => {
    dispatch({
      type: UPDATE_TOP_LPS,
      payload: {
        topLps,
      },
    })
  }, [])

  const updateTopPoints = useCallback((topPoints) => {
    dispatch({
      type: UPDATE_TOP_POINTS,
      payload: {
        topPoints,
      },
    })
  }, [])

  const updateHistoryPoints = useCallback((historyPoints) => {
    dispatch({
      type: UPDATE_HISTORY_POINTS,
      payload: {
        historyPoints,
      },
    })
  }, [])
  return (
    <GlobalDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updateTransactions,
            addTransactions,
            updateMints,
            addMints,
            updateBurns,
            addBurns,
            updateChart,
            updateEthPrice,
            updateTopLps,
            updateAllPairsInUniswap,
            updateAllTokensInUniswap,
            updateTopPoints,
            updateHistoryPoints,
          },
        ],
        [
          state,
          update,
          updateTransactions,
          addTransactions,
          updateMints,
          addMints,
          updateBurns,
          addBurns,
          updateTopLps,
          updateChart,
          updateEthPrice,
          updateAllPairsInUniswap,
          updateAllTokensInUniswap,
          updateTopPoints,
          updateHistoryPoints,
        ]
      )}
    >
      {children}
    </GlobalDataContext.Provider>
  )
}

/**
 * Gets all the global data for the overview page.
 * Needs current eth price and the old eth price to get
 * 24 hour USD changes.
 * @param {*} ethPrice
 * @param {*} oldEthPrice
 */

async function getGlobalData(ethPrice, oldEthPrice) {
  // data for each day , historic data used for % changes
  let data = {}
  let oneDayData = {}
  let twoDayData = {}

  try {
    // get timestamps for the days
    const utcCurrentTime = dayjs()
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcTwoWeeksBack = utcCurrentTime.subtract(2, 'week').unix()

    // get the blocks needed for time travel queries
    let [oneDayBlock, twoDayBlock, oneWeekBlock, twoWeekBlock] = await getBlocksFromTimestamps([
      utcOneDayBack,
      utcTwoDaysBack,
      utcOneWeekBack,
      utcTwoWeeksBack,
    ])

    // fetch the global data
    let result = await client.query({
      query: GLOBAL_DATA(),
      fetchPolicy: 'cache-first',
    })
    data = result.data.fxswapFactories[0]
    // data = result.data.uniswapFactories[0]

    // fetch the historical data
    let oneDayResult = await client.query({
      query: GLOBAL_DATA(oneDayBlock?.number),
      fetchPolicy: 'cache-first',
    })
    oneDayData = oneDayResult.data.fxswapFactories[0]
    // oneDayData = oneDayResult.data.uniswapFactories[0]

    let twoDayResult = await client.query({
      query: GLOBAL_DATA(twoDayBlock?.number),
      fetchPolicy: 'cache-first',
    })
    twoDayData = twoDayResult.data.fxswapFactories[0]
    // twoDayData = twoDayResult.data.uniswapFactories[0]

    let oneWeekResult = await client.query({
      query: GLOBAL_DATA(oneWeekBlock?.number),
      fetchPolicy: 'cache-first',
    })
    const oneWeekData = oneWeekResult.data.fxswapFactories[0]
    // const oneWeekData = oneWeekResult.data.uniswapFactories[0]

    let twoWeekResult = await client.query({
      query: GLOBAL_DATA(twoWeekBlock?.number),
      fetchPolicy: 'cache-first',
    })
    const twoWeekData = twoWeekResult.data.fxswapFactories[0]
    // const twoWeekData = twoWeekResult.data.uniswapFactories[0]

    if (data && oneDayData && twoDayData && twoWeekData) {
      let [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
        data.totalVolumeUSD,
        oneDayData.totalVolumeUSD,
        twoDayData.totalVolumeUSD
      )

      const [oneWeekVolume, weeklyVolumeChange] = get2DayPercentChange(
        data.totalVolumeUSD,
        oneWeekData.totalVolumeUSD,
        twoWeekData.totalVolumeUSD
      )

      const [oneDayTxns, txnChange] = get2DayPercentChange(
        data.txCount,
        oneDayData.txCount ? oneDayData.txCount : 0,
        twoDayData.txCount ? twoDayData.txCount : 0
      )

      // format the total liquidity in USD
      data.totalLiquidityUSD = data.totalLiquidityETH * ethPrice
      const liquidityChangeUSD = getPercentChange(
        data.totalLiquidityETH * ethPrice,
        oneDayData.totalLiquidityETH * oldEthPrice
      )

      // add relevant fields with the calculated amounts
      data.oneDayVolumeUSD = oneDayVolumeUSD
      data.oneWeekVolume = oneWeekVolume
      data.weeklyVolumeChange = weeklyVolumeChange
      data.volumeChangeUSD = volumeChangeUSD
      data.liquidityChangeUSD = liquidityChangeUSD
      data.oneDayTxns = oneDayTxns
      data.txnChange = txnChange
    }
  } catch (e) {
    console.log(e)
  }

  return data
}

/**
 * Get historical data for volume and liquidity used in global charts
 * on main page
 * @param {*} oldestDateToFetch // start of window to fetch from
 */

let checked = false

const getChartData = async (oldestDateToFetch, offsetData) => {
  let data = []
  let weeklyData = []
  const utcEndTime = dayjs.utc()
  let skip = 0
  let allFound = false

  try {
    while (!allFound) {
      let result = await client.query({
        query: GLOBAL_CHART,
        variables: {
          startTime: oldestDateToFetch,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      // data = data.concat(result.data.uniswapDayDatas)
      // if (result.data.uniswapDayDatas.length < 1000) {
      data = data.concat(result.data.fxswapDayDatas)
      if (result.data.fxswapDayDatas.length < 1000) {
        allFound = true
      }
    }

    if (data) {
      let dayIndexSet = new Set()
      let dayIndexArray = []
      const oneDay = 24 * 60 * 60

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData, i) => {
        // add the day index to the set of days
        dayIndexSet.add((data[i].date / oneDay).toFixed(0))
        dayIndexArray.push(data[i])
        dayData.dailyVolumeUSD = parseFloat(dayData.dailyVolumeUSD)
      })

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0].date ? data[0].date : oldestDateToFetch
      let latestLiquidityUSD = data[0].totalLiquidityUSD
      let latestDayDats = data[0].mostLiquidTokens
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        let currentDayIndex = (nextDay / oneDay).toFixed(0)

        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
          latestDayDats = dayIndexArray[index].mostLiquidTokens
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    let startIndexWeekly = -1
    let currentWeek = -1

    data.forEach((entry, i) => {
      const date = data[i].date

      // hardcoded fix for offset volume
      offsetData &&
        !checked &&
        offsetData.map((dayData) => {
          if (dayData[date]) {
            data[i].dailyVolumeUSD = parseFloat(data[i].dailyVolumeUSD) - parseFloat(dayData[date].dailyVolumeUSD)
          }
          return true
        })

      const week = dayjs.utc(dayjs.unix(data[i].date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        startIndexWeekly++
      }
      weeklyData[startIndexWeekly] = weeklyData[startIndexWeekly] || {}
      weeklyData[startIndexWeekly].date = data[i].date
      weeklyData[startIndexWeekly].weeklyVolumeUSD =
        (weeklyData[startIndexWeekly].weeklyVolumeUSD ?? 0) + data[i].dailyVolumeUSD
    })

    if (!checked) {
      checked = true
    }
  } catch (e) {
    console.log(e)
  }
  return [data, weeklyData]
}

/**
 * Get and format transactions for global page
 */
const getGlobalTransactions = async (maxItem = 100, page = 0) => {

  let first = maxItem
  let skip = page * maxItem
  let transactions = {}

  try {
    let result = await client.query({
      query: GLOBAL_TXNS(first, skip),
      fetchPolicy: 'cache-first',
    })
    transactions.mints = []
    transactions.burns = []
    transactions.swaps = []
    result?.data?.transactions &&
      result.data.transactions.map((transaction) => {
        if (transaction.mints.length > 0) {
          transaction.mints.map((mint) => {
            return transactions.mints.push(mint)
          })
        }
        if (transaction.burns.length > 0) {
          transaction.burns.map((burn) => {
            return transactions.burns.push(burn)
          })
        }
        if (transaction.swaps.length > 0) {
          transaction.swaps.map((swap) => {
            return transactions.swaps.push(swap)
          })
        }
        return true
      })
    // console.log(result)
  } catch (e) {
    console.log(e)
  }

  return transactions
}

const getGlobalMints = async (maxItem = 100, page = 0) => {

  let first = maxItem
  let skip = page * maxItem
  let mints = []

  try {
    let result = await client.query({
      query: GLOBAL_MINTS(first, skip),
      fetchPolicy: 'cache-first',
    })
    mints = result?.data?.mints
  } catch (e) {
    console.log(e)
  }

  return mints
}

const getGlobalBurns = async (maxItem = 100, page = 0) => {

  let first = maxItem
  let skip = page * maxItem
  let burns = []

  try {
    let result = await client.query({
      query: GLOBAL_BURNS(first, skip),
      fetchPolicy: 'cache-first',
    })
    burns = result?.data?.burns
  } catch (e) {
    console.log(e)
  }

  return burns
}


/**
 * Gets the current price  of ETH, 24 hour price, and % change between them
 */
const getEthPrice = async () => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()
  const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').startOf('minute').unix()
  const utcOneMonthBack = utcCurrentTime.subtract(1, 'month').startOf('minute').unix()

  let ethPrice = 0
  let ethPriceOneDay = 0
  let ethPriceOneWeek = 0
  let ethPriceOneMonth = 0
  let priceChangeETH = 0

  try {
    let oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
    let oneWeekBlock = await getBlockFromTimestamp(utcOneWeekBack)
    let oneMonthBlock = await getBlockFromTimestamp(utcOneMonthBack)

    let result = await client.query({
      query: ETH_PRICE(),
      fetchPolicy: 'cache-first',
    })
    let resultOneDay = await client.query({
      query: ETH_PRICE(oneDayBlock),
      fetchPolicy: 'cache-first',
    })
    let resultOneWeek = await client.query({
      query: ETH_PRICE(oneWeekBlock),
      fetchPolicy: 'cache-first',
    })
    let resultOneMonth = await client.query({
      query: ETH_PRICE(oneMonthBlock),
      fetchPolicy: 'cache-first',
    })
    const currentPrice = result?.data?.bundles[0]?.ethPrice
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.ethPrice
    const oneWeekBackPrice = resultOneWeek?.data?.bundles[0]?.ethPrice
    const oneMonthBackPrice = resultOneMonth?.data?.bundles[0]?.ethPrice
    priceChangeETH = getPercentChange(currentPrice, oneDayBackPrice)
    ethPrice = currentPrice
    ethPriceOneDay = oneDayBackPrice
    ethPriceOneWeek = oneWeekBackPrice
    ethPriceOneMonth = oneMonthBackPrice
  } catch (e) {
    console.log(e)
  }

  return [ethPrice, ethPriceOneDay, priceChangeETH, ethPriceOneWeek, ethPriceOneMonth]
}

const PAIRS_TO_FETCH = 500
const TOKENS_TO_FETCH = 500

/**
 * Loop through every pair on uniswap, used for search
 */
async function getAllPairsOnUniswap() {
  try {
    let allFound = false
    let pairs = []
    let skipCount = 0
    while (!allFound) {
      let result = await client.query({
        query: ALL_PAIRS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      skipCount = skipCount + PAIRS_TO_FETCH
      pairs = pairs.concat(result?.data?.pairs)
      if (result?.data?.pairs.length < PAIRS_TO_FETCH || pairs.length > PAIRS_TO_FETCH) {
        allFound = true
      }
    }
    return pairs
  } catch (e) {
    console.log(e)
  }
}

/**
 * Loop through every token on uniswap, used for search
 */
async function getAllTokensOnUniswap() {
  try {
    let allFound = false
    let skipCount = 0
    let tokens = []
    while (!allFound) {
      let result = await client.query({
        query: ALL_TOKENS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      tokens = tokens.concat(result?.data?.tokens)
      if (result?.data?.tokens?.length < TOKENS_TO_FETCH || tokens.length > TOKENS_TO_FETCH) {
        allFound = true
      }
      skipCount = skipCount += TOKENS_TO_FETCH
    }
    return tokens
  } catch (e) {
    console.log(e)
  }
}

/**
 * Hook that fetches overview data, plus all tokens and pairs for search
 */
export function useGlobalData() {
  const [state, { update, updateAllPairsInUniswap, updateAllTokensInUniswap }] = useGlobalDataContext()
  const [ethPrice, oldEthPrice] = useEthPrice()

  const data = state?.globalData

  // const combinedVolume = useTokenDataCombined(offsetVolumes)

  useEffect(() => {
    async function fetchData() {
      let globalData = await getGlobalData(ethPrice, oldEthPrice)

      globalData && update(globalData)

      let allPairs = await getAllPairsOnUniswap()
      updateAllPairsInUniswap(allPairs)

      let allTokens = await getAllTokensOnUniswap()
      updateAllTokensInUniswap(allTokens)
    }
    if (!data && ethPrice && oldEthPrice) {
      fetchData()
    }
  }, [ethPrice, oldEthPrice, update, data, updateAllPairsInUniswap, updateAllTokensInUniswap])

  return data || {}
}

export function useGlobalChartData() {
  const [state, { updateChart }] = useGlobalDataContext()
  const [oldestDateFetch, setOldestDateFetched] = useState()
  const [activeWindow] = useTimeframe()

  const chartDataDaily = state?.chartData?.daily
  const chartDataWeekly = state?.chartData?.weekly

  /**
   * Keep track of oldest date fetched. Used to
   * limit data fetched until its actually needed.
   * (dont fetch year long stuff unless year option selected)
   */
  useEffect(() => {
    // based on window, get starttime
    let startTime = getTimeframe(activeWindow)

    if ((activeWindow && startTime < oldestDateFetch) || !oldestDateFetch) {
      setOldestDateFetched(startTime)
    }
  }, [activeWindow, oldestDateFetch])

  // fix for rebass tokens

  const combinedData = useTokenChartDataCombined(offsetVolumes)

  /**
   * Fetch data if none fetched or older data is needed
   */
  useEffect(() => {
    async function fetchData() {
      // historical stuff for chart
      let [newChartData, newWeeklyData] = await getChartData(oldestDateFetch, combinedData)
      updateChart(newChartData, newWeeklyData)
    }
    if (oldestDateFetch && !(chartDataDaily && chartDataWeekly) && combinedData) {
      fetchData()
    }
  }, [chartDataDaily, chartDataWeekly, combinedData, oldestDateFetch, updateChart])

  return [chartDataDaily, chartDataWeekly]
}

export function useGlobalTransactions(maxItem = 100) {
  const [state, { updateTransactions, addTransactions }] = useGlobalDataContext()
  const [page, setPage] = useState(0)
  const transactions = state?.transactions

  // useEffect(() => {
  //   async function fetchData() {
  //     if (!transactions) {
  //       let txns = await getGlobalTransactions(maxItem, page)
  //       updateTransactions(txns)
  //     }
  //   }
  //   fetchData()
  // }, [updateTransactions, transactions, page])
  useEffect(() => {
    async function fetchData() {
      let txns = await getGlobalTransactions(maxItem, page)
      if (!transactions || page === 0) updateTransactions(txns)
      else addTransactions(txns)
    }
    fetchData()
  }, [page])
  return [transactions, setPage]
}

export function useGlobalMints(maxItem = 100) {
  const [state, { updateMints, addMints }] = useGlobalDataContext()
  const [page, setPage] = useState(0)
  const mints = state?.mints

  useEffect(() => {
    async function fetchData() {
      let txns = await getGlobalMints(maxItem, page)
      if (!mints || page === 0) updateMints(txns)
      else addMints(txns)
    }
    fetchData()
  }, [page])
  return [mints, setPage]
}

export function useGlobalBurns(maxItem = 100) {
  const [state, { updateBurns, addBurns }] = useGlobalDataContext()
  const [page, setPage] = useState(0)
  const burns = state?.burns

  useEffect(() => {
    async function fetchData() {
      let txns = await getGlobalBurns(maxItem, page)
      // console.log('tx', txns)
      if (!burns || page === 0) updateBurns(txns)
      else addBurns(txns)
    }
    fetchData()
  }, [page])
  return [burns, setPage]
}

export function useEthPrice() {
  const [state, { updateEthPrice }] = useGlobalDataContext()
  const ethPrice = state?.[ETH_PRICE_KEY]
  const ethPriceOld = state?.['oneDayPrice']
  const ethPriceOneWeek = state?.['oneWeekPrice']
  const ethPriceOneMonth = state?.['oneMonthPrice']
  useEffect(() => {
    async function checkForEthPrice() {
      if (!ethPrice) {
        let [newPrice, oneDayPrice, priceChange, oneWeekPrice, oneMonthPrice] = await getEthPrice()
        updateEthPrice(newPrice, oneDayPrice, priceChange, oneWeekPrice, oneMonthPrice)
      }
    }
    checkForEthPrice()
  }, [ethPrice, updateEthPrice])

  return [ethPrice, ethPriceOld, ethPriceOneWeek, ethPriceOneMonth]
}

export function useAllPairsInUniswap() {
  const [state] = useGlobalDataContext()
  let allPairs = state?.allPairs

  return allPairs || []
}

export function useAllTokensInUniswap() {
  const [state] = useGlobalDataContext()
  let allTokens = state?.allTokens

  return allTokens || []
}

/**
 * Get the top liquidity positions based on USD size
 * @TODO Not a perfect lookup needs improvement
 */
export function useTopLps() {
  const [state, { updateTopLps }] = useGlobalDataContext()
  let topLps = state?.topLps

  const allPairs = useAllPairData()

  useEffect(() => {
    async function fetchData() {
      // get top 20 by reserves
      let topPairs = Object.keys(allPairs)
        ?.sort((a, b) => parseFloat(allPairs[a].reserveUSD > allPairs[b].reserveUSD ? -1 : 1))
        ?.slice(0, 99)
        .map((pair) => pair)

      let topLpLists = await Promise.all(
        topPairs.map(async (pair) => {
          // for each one, fetch top LPs
          try {
            const { data: results } = await client.query({
              query: TOP_LPS_PER_PAIRS,
              variables: {
                pair: pair.toString(),
              },
              fetchPolicy: 'cache-first',
            })
            if (results) {
              return results.liquidityPositions
            }
          } catch (e) { }
        })
      )

      // get the top lps from the results formatted
      const topLps = []
      topLpLists
        .filter((i) => !!i) // check for ones not fetched correctly
        .map((list) => {
          return list.map((entry) => {
            const pairData = allPairs[entry.pair.id]
            return topLps.push({
              user: entry.user,
              pairName: pairData.token0.symbol + '-' + pairData.token1.symbol,
              pairAddress: entry.pair.id,
              token0: pairData.token0.id,
              token1: pairData.token1.id,
              usd:
                (parseFloat(entry.liquidityTokenBalance) / parseFloat(pairData.totalSupply)) *
                parseFloat(pairData.reserveUSD),
            })
          })
        })

      const sorted = topLps.sort((a, b) => (a.usd > b.usd ? -1 : 1))
      const shorter = sorted.splice(0, 100)
      updateTopLps(shorter)
    }

    if (!topLps && allPairs && Object.keys(allPairs).length > 0) {
      fetchData()
    }
  })

  return topLps
}

export function useTopPoints() {
  const [state, { updateTopPoints }] = useGlobalDataContext()
  let topPoints = state?.topPoints

  useEffect(() => {
    async function fetchData() {
      try {
        let result = await client.query({
          query: POINTS_LIST,
          fetchPolicy: 'cache-first'
        })
        let topList = result?.data.users

        updateTopPoints(topList)

      } catch (e) {
        console.log(e)
      }


    }

    fetchData()
  }, [])

  return topPoints
}

export function useHistoryPoints() {
  // const [historyData, setHistoryData] = useState()
  const [state, { updateHistoryPoints }] = useGlobalDataContext()
  let historyPoints = state?.historyPoints

  useEffect(() => {
    async function fetchData() {
      try {

        let result = await client.query({
          query: TOP_ACCOUNTS_LIST(AUTOSWAP_ADDRESSES),
          fetchPolicy: 'cache-first',
        })
        let current = result?.data.users
        let topList = current?.map((account) => {
          return account.id
        })

        const fxProvider = new providers.JsonRpcProvider(`https://fx-json-web3.portfolio-x.xyz:8545`)
        const contractChecker = new Contract("0xDcf6B935EB3F46509b7eFc909C44bd1bC4D4e08B", ContractCheckerAbi.abi, fxProvider)
        const eoaList = await contractChecker.batchCheckEOA(topList)

        const [t1, tWeek, tMonth] = get1D_7D_1M_Timestamp()
        let [{ number: b1 }, { number: bWeek }, { number: bMonth }] = await getBlocksFromTimestamps([t1, tWeek, tMonth])

        let [oneDayResult, oneWeekResult, oneMonthResult] = await Promise.all(
          [b1, bWeek, bMonth].map(async (block) => {
            let result = client.query({
              query: TOP_ACCOUNTS_HISTORICAL_LIST(block),
              fetchPolicy: 'cache-first',
              variables: {
                topList: topList
              }
            })
            return result
          })
        )

        let oneDayData = oneDayResult?.data?.users.reduce((obj, cur, i) => {
          return { ...obj, [cur.id]: cur }
        }, {})

        let oneWeekData = oneWeekResult?.data?.users.reduce((obj, cur, i) => {
          return { ...obj, [cur.id]: cur }
        }, {})

        let oneMonthData = oneMonthResult?.data?.users.reduce((obj, cur, i) => {
          return { ...obj, [cur.id]: cur }
        }, {})

        let historyData = current.map((user, index) => {
          let oneDayPoints = parseFloat(user.cumulativePoint) - parseFloat(oneDayData[user.id]?.cumulativePoint ?? 0)
          let oneWeekPoints = parseFloat(user.cumulativePoint) - parseFloat(oneWeekData[user.id]?.cumulativePoint ?? 0)
          let oneMonthPoints = parseFloat(user.cumulativePoint) - parseFloat(oneMonthData[user.id]?.cumulativePoint ?? 0)
          let isBlacklist = Blacklist.blacklist.includes(user.id)
          return { ...user, oneDayPoints, oneWeekPoints, oneMonthPoints, index, isEoa: eoaList[index] && !isBlacklist }
        })
        console.log(historyData)
        updateHistoryPoints(historyData)
      } catch (e) {
        console.log(e)
      }
    }

    if (!historyPoints || historyPoints?.length === 0) fetchData()
  }, [historyPoints])

  return historyPoints
}