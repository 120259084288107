import React, { useEffect } from 'react'
import 'feather-icons'
import { withRouter } from 'react-router-dom'
import { TYPE, ThemedBackground } from '../Theme'
import { transparentize } from 'polished'
import { PageWrapper, FullWrapper } from '../components'
import Panel from '../components/Panel'
import PointsList from '../components/PointsList'
import styled from 'styled-components'
import { useHistoryPoints, useTopLps, useTopPoints } from '../contexts/GlobalData'
import LocalLoader from '../components/LocalLoader'
import { RowBetween } from '../components/Row'
import { useMedia } from 'react-use'

const AccountWrapper = styled.div`
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

function AllPointsPage() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const historyPoints = useHistoryPoints()

  const below600 = useMedia('(max-width: 600px)')

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.6, '#18daa6')} />
      <FullWrapper>
        <RowBetween>
          <TYPE.largeHeader>Top 100 Accounts</TYPE.largeHeader>
        </RowBetween>
        <Panel>{historyPoints && historyPoints.length > 0 ? <PointsList maxItems={100} historyPoints={historyPoints} /> : <LocalLoader />}</Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default withRouter(AllPointsPage)
