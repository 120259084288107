import { useEffect } from 'react'
import ReactGA from 'react-ga4'

// fires a GA pageview every time the route changes
export default function GoogleAnalyticsReporter({ location: { pathname, search } }) {
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: `${pathname}${search}`, title: `${pathname}` });
  }, [pathname, search])
  return null
}
