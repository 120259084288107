import React, { useEffect, useState } from 'react'
import 'feather-icons'

import TopTokenList from '../components/TokenList'
import styled from 'styled-components'
import { TYPE, ThemedBackground } from '../Theme'
import { transparentize } from 'polished'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { PageWrapper, FullWrapper } from '../components'
import Row, { RowBetween, RowFixed, RowFlat } from '../components/Row'
import { AutoColumn } from '../components/Column'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import { ButtonDropdown } from '../components/ButtonStyled'
// import QuestionHelper from '../components/QuestionHelper'

const DropdownWrapper = styled.div`
  position: relative;
  border: 1px solid #edeef2;
  border-radius: 12px;
  margin-left: 10px;
`

const Flyout = styled.div`
  position: absolute;
  top: 34px;
  left: -1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg1};
  z-index: 999;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 2px;
  border: 1px solid #edeef2;
  border-top: none;
`

const MenuRow = styled(Row)`
  width: 100%;
  height: 36px;
  padding: 12px 0;
  padding-left: 12px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.bg2};
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`

function AllTokensPage() {
  const allTokens = useAllTokenData()
  const [showDropdown, setShowDropdown] = useState(false)
  const [timeBasis, setTimeBasis] = useState('24H')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below600 = useMedia('(max-width: 800px)')

  // const [useTracked, setUseTracked] = useState(true)


  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.6, '#18daa6')} />
      <FullWrapper>
        <RowBetween>
          <TYPE.largeHeader>Top Tokens</TYPE.largeHeader>
          <RowFlat>
            {!below600 && <Search small={true} />}
            <DropdownWrapper>
              <ButtonDropdown width="100%" height="42px" style={{ "padding": '5px 12px' }} onClick={() => setShowDropdown(!showDropdown)} open={showDropdown}>
                <RowFixed>
                  <TYPE.body ml={'5px'}>Time Basis: {timeBasis}</TYPE.body>
                </RowFixed>
              </ButtonDropdown>
              {showDropdown && (
                <Flyout>
                  <AutoColumn gap="0px">
                    <MenuRow
                      onClick={() => {
                        setShowDropdown(false)
                        setTimeBasis('24H')
                      }}
                    >
                      <TYPE.body ml={'16px'}>
                        24H
                        {timeBasis === '24H' && '  ✓'}
                      </TYPE.body>
                    </MenuRow>
                    <MenuRow
                      onClick={() => {
                        setShowDropdown(false)
                        setTimeBasis('7D')
                      }}
                    >
                      <TYPE.body ml={'16px'}>
                        7D
                        {timeBasis === '7D' && '  ✓'}
                      </TYPE.body>
                    </MenuRow>
                    <MenuRow
                      onClick={() => {
                        setShowDropdown(false)
                        setTimeBasis('30D')
                      }}
                    >
                      <TYPE.body ml={'16px'}>
                        30D
                        {timeBasis === '30D' && '  ✓'}
                      </TYPE.body>
                    </MenuRow>
                  </AutoColumn>
                </Flyout>
              )}
            </DropdownWrapper>
          </RowFlat>
        </RowBetween>
        <Panel style={{ marginTop: '3px', padding: below600 && '1rem 0 0 0 ' }}>
          <TopTokenList tokens={allTokens} itemMax={100} timeBasis={timeBasis} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllTokensPage
