import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { formatTime, formatTimestamp, formattedNum, urls, formattedPoint } from '../../utils'
import { useMedia } from 'react-use'
import { useCurrentCurrency } from '../../contexts/Application'
import { RowFixed, RowBetween } from '../Row'

import LocalLoader from '../LocalLoader'
import { Box, Flex, Text } from 'rebass'
import Link from '../Link'
import { Divider, EmptyCard } from '..'
import DropdownSelect from '../DropdownSelect'
import FormattedName from '../FormattedName'
import { TYPE } from '../../Theme'
import { updateNameData } from '../../utils/data'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div`
  color: #32d9b5;
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  max-width: 1320px;
  grid-template-columns: 0.6fr 1fr 1fr 1fr 1fr;
  grid-template-areas: 'txn value amountToken amountOther time';

  > * {
    justify-content: flex-end;
    width: 100%;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
    }

    &:nth-child(2) {
      justify-content: flex-start;
      text-align: left;
    }
  }

`

const ClickableText = styled(Text)`
  color: ${({ theme }) => theme.text1};
  user-select: none;
  text-align: end;

  &:hover {
    cursor: ${({ disabled }) => !disabled && "pointer"};
    opacity: ${({ disabled }) => !disabled && "0.6"};
  }

  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`

const DataText = styled(Flex)`
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.text1};

  & > * {
    font-size: 1em;
  }

  @media screen and (max-width: 40em) {
    font-size: 0.85rem;
  }
`

const SortText = styled.button`
  cursor: pointer;
  font-weight: ${({ active, theme }) => (active ? 500 : 400)};
  margin-right: 0.75rem !important;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  padding: 0px;
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text3)};
  outline: none;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`


const TXN_TYPE = {
    ALL: 'All',
    SWAP: 'Swaps',
    ADD: 'Adds',
    REMOVE: 'Removes',
}


function getTransactionType(event, symbol0, symbol1) {
    const formattedS0 = symbol0?.length > 8 ? symbol0.slice(0, 7) + '...' : symbol0
    const formattedS1 = symbol1?.length > 8 ? symbol1.slice(0, 7) + '...' : symbol1
    switch (event) {
        case TXN_TYPE.ADD:
            return 'Add ' + formattedS0 + ' and ' + formattedS1
        case TXN_TYPE.REMOVE:
            return 'Remove ' + formattedS0 + ' and ' + formattedS1
        case TXN_TYPE.SWAP:
            return 'Swap ' + formattedS0 + ' for ' + formattedS1
        default:
            return ''
    }
}

// @TODO rework into virtualized list
function AccountPointList({ trades, transactions, color, maxItems, loadNext, scroll, showAllPage }) {
    const ITEMS_PER_PAGE = maxItems ?? 10
    // page state
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)

    // sorting
    const [filteredItems, setFilteredItems] = useState()
    const [txFilter, setTxFilter] = useState(TXN_TYPE.ALL)

    const [currency] = useCurrentCurrency()
    useEffect(() => {

        scroll && scroll()
        if (maxPage !== 1 && (page === maxPage - 1)) {
            loadNext && (txFilter === TXN_TYPE.ALL || txFilter === TXN_TYPE.SWAP) && loadNext((_page) => _page + 1)

        }
    }, [page])

    // useEffect(() => {
    //   setMaxPage(1) // edit this to do modular
    //   setPage(1)
    // }, [transactions])

    // parse the txns and format for UI
    useEffect(() => {
        if (trades && trades.constructor !== Object) {
            let newTxns = []

            if (trades.length > 0) {
                trades.map((swap) => {
                    const netToken0 = swap.amount0In - swap.amount0Out
                    const netToken1 = swap.amount1In - swap.amount1Out

                    let newTxn = {}

                    if (netToken0 < 0) {
                        newTxn.token0Symbol = updateNameData(swap.pair).token0.symbol
                        newTxn.token1Symbol = updateNameData(swap.pair).token1.symbol
                        newTxn.token0Amount = Math.abs(netToken0)
                        newTxn.token1Amount = Math.abs(netToken1)
                    } else if (netToken1 < 0) {
                        newTxn.token0Symbol = updateNameData(swap.pair).token1.symbol
                        newTxn.token1Symbol = updateNameData(swap.pair).token0.symbol
                        newTxn.token0Amount = Math.abs(netToken1)
                        newTxn.token1Amount = Math.abs(netToken0)
                    }

                    newTxn.hash = swap.transaction.id
                    newTxn.timestamp = swap.transaction.timestamp
                    newTxn.cumulativePoint = swap.cumulativePoint
                    newTxn.type = TXN_TYPE.SWAP

                    newTxn.amountUSD = swap.amountUSD
                    return newTxns.push(newTxn)
                })
            }

            const filtered = newTxns
            setFilteredItems(filtered)
            let extraPages = 1
            if (filtered.length % ITEMS_PER_PAGE === 0) {
                extraPages = 0
            }
            if (filtered.length === 0) {
                setMaxPage(1)
            } else {
                setMaxPage(Math.floor(filtered.length / ITEMS_PER_PAGE) + extraPages)
            }
        }

    }, [trades])

    useEffect(() => {
        setPage(1)
    }, [txFilter])
    let point = 0

    // use calculated point from subgraph, sort by cumulativePoint
    const sortedList = filteredItems
    // filteredItems &&
    // filteredItems
    //     .sort((a, b) => {
    //         return parseFloat(a["timestamp"]) > parseFloat(b["timestamp"])
    //             ? -1
    //             : 1
    //     })
    // .map((tx) => {
    //     point += parseFloat(tx.amountUSD)
    //     return { ...tx, pointChange: tx.amountUSD, cumulativePoint: point }
    // }).reverse()
    const filteredList =
        sortedList &&
        sortedList
            .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)

    const below1080 = useMedia('(max-width: 1080px)')
    const below780 = useMedia('(max-width: 780px)')

    const ListItem = ({ item }) => {
        return (
            <DashGrid style={{ height: '48px' }}>
                <DataText area="time">{formatTime(item.timestamp)}</DataText>
                <DataText area="txn" fontWeight="500">
                    <Link color={color} external href={urls.showTransaction(item.hash)}>
                        {getTransactionType(item.type, item.token1Symbol, item.token0Symbol)}
                    </Link>
                </DataText>
                <DataText area="value">
                    {currency === 'ETH' ? 'Ξ ' + formattedNum(item.valueETH) : formattedNum(item.amountUSD, true)}
                </DataText>
                <DataText area="pointChange">
                    {formattedPoint(item.amountUSD)}
                </DataText>
                <DataText area="cumulativePoint">
                    {formattedNum(item.cumulativePoint)}
                </DataText>

            </DashGrid>
        )
    }

    return (
        <>
            <DashGrid center={true} style={{ height: 'fit-content', padding: '0 0 1rem 0' }}>
                <Flex alignItems="center">
                    <TYPE.body area="account">Time</TYPE.body>
                </Flex>
                <Flex alignItems="center" justifyContent="flexStart">
                    <TYPE.body area="account">Activity</TYPE.body>
                </Flex>
                <Flex alignItems="center">
                    <TYPE.body area="account">Trade Value</TYPE.body>
                </Flex>
                <Flex alignItems="center">
                    <TYPE.body area="account">Change</TYPE.body>
                </Flex>
                <Flex alignItems="center">
                    <TYPE.body area="account">Point Balance</TYPE.body>
                </Flex>
            </DashGrid>
            <Divider />
            <List p={0}>
                {!sortedList ? (
                    <LocalLoader />
                ) : filteredList.length === 0 ? (
                    <EmptyCard>No recent transactions found.</EmptyCard>
                ) : (
                    filteredList.map((item, index) => {
                        return (
                            <div key={index}>
                                <ListItem key={index} index={index + 1} item={item} />
                                <Divider />
                            </div>
                        )
                    })
                )}
            </List>
            <PageButtons>
                <div
                    onClick={(e) => {
                        setPage(page === 1 ? page : page - 1)
                    }}
                >
                    <Arrow faded={page === 1 ? true : false}>←</Arrow>
                </div>
                <TYPE.body>{'Page ' + page}{showAllPage && ` of ${maxPage}`}</TYPE.body>
                <div
                    onClick={(e) => {
                        setPage(page === maxPage ? page : page + 1)
                    }}
                >
                    <Arrow faded={page === maxPage ? true : false}>→</Arrow>
                </div>
            </PageButtons>
        </>
    )
}

export default AccountPointList
